/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import preload from 'api/data-preload';
import seoPreload, { seoDecorator } from 'api/seo';
import GeoLandings from 'subsys/geo-landings';
import contentBlock, { blockPreloader } from 'containers/content-block';
import Container from 'components/container';
import Breadcrumbs from 'components/breadcrumbs';
import { OpenGraphImage } from 'components/image';
import { pageCacheStatic } from 'api/page-cache';
import cacheTerms from 'helpers/cacheTerms';

import linksDomik from 'src-sites/domik/links';
import blockConfig from 'src-sites/domik/blocks/config';
import BlockWrapper from 'src-sites/domik/blocks/block-wrapper';

import SearchBlock from './block-search';

import translates from './page-home-i18n';

import 'styles/domik/page-home/_page-home.scss';

const bemClasses = new Bem('pageHome');

@preload({
  ...seoPreload({ entity: 'mainPage' }, []),
  ...blockPreloader({
    project: 'domik',
    entity: 'mainPage'
  }, blockConfig, []),
  ...pageCacheStatic(cacheTerms.shortPage, true)
})
@seoDecorator({
  advancedVars: {
    bottomText: 'Текст в самом низу'
  }
})
@I18nHoc(translates())
class Home extends Component {
  static propTypes = {
    className: PropTypes.string,
    seoData: PropTypes.shape({}),
    mainMenuBase: PropTypes.shape({})
  };

  render() {
    const { i18n } = this;
    const { className, mainMenuBase, seoData } = this.props;
    const header = get(seoData, 'header') ? seoData.header : i18n('portalRealEstate');

    const dataBreadcrumbs = [
      {
        name: i18n('main'),
        builder: linksDomik.sectionDomik,
        builderProps: { title: i18n('main') }
      }
    ];

    return (
      <div {...bemClasses({ extra: className })}>
        <LazyHydrate ssrOnly>
          <OpenGraphImage />
        </LazyHydrate>
        <div {...bemClasses('content')}>
          <Container body>
            <div {...bemClasses('title')}>
              <div {...bemClasses('text')}><h1 children={header} /></div>
            </div>
            <div {...bemClasses('searchBlock')}>
              <SearchBlock />
            </div>
          </Container>
        </div>
        <div {...bemClasses('blocks')}>
          <GeoLandings
            injectPropName="landingLinks"
            base={mainMenuBase}
            project="domik"
          >
            <GeoLandingsWrapper {...this.props} />
          </GeoLandings>
        </div>
        <Container body>
          <Breadcrumbs data={dataBreadcrumbs} />
        </Container>
      </div>
    );
  }
}

class GeoLandingsWrapper extends PureComponent {
  static propTypes = {
    landingLinks: PropTypes.shape({}),
    geolocation: PropTypes.shape({})
  };

  render() {
    const { landingLinks, geolocation } = this.props;

    return (
      <>
        <LazyHydrate whenVisible>
          {contentBlock(blockConfig, 'main1', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
          {contentBlock(blockConfig, 'main2', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
        </LazyHydrate>
        <LazyHydrate whenVisible>
          <Container {...bemClasses('blocks', 'row')} body>
            {contentBlock(blockConfig, 'main3', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
            {contentBlock(blockConfig, 'main4', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
          </Container>
        </LazyHydrate>
        <LazyHydrate whenVisible>
          {contentBlock(blockConfig, 'main5', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
          {contentBlock(blockConfig, 'main6', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
        </LazyHydrate>
        <LazyHydrate whenVisible>
          <Container {...bemClasses('blocks', 'row')} body>
            {contentBlock(blockConfig, 'main7', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
            {contentBlock(blockConfig, 'main8', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
          </Container>
        </LazyHydrate>
        <LazyHydrate whenVisible>
          {contentBlock(blockConfig, 'main9', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
          {contentBlock(blockConfig, 'main10', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
        </LazyHydrate>
        <LazyHydrate whenVisible>
          <Container {...bemClasses('blocks', 'row')} body>
            {contentBlock(blockConfig, 'main11', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
            {contentBlock(blockConfig, 'main12', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
          </Container>
        </LazyHydrate>
        <LazyHydrate whenVisible>
          {contentBlock(blockConfig, 'main13', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
          {contentBlock(blockConfig, 'main14', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
        </LazyHydrate>
        <LazyHydrate whenVisible>
          <Container {...bemClasses('blocks', 'row')} body>
            {contentBlock(blockConfig, 'main15', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
            {contentBlock(blockConfig, 'main16', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
          </Container>
        </LazyHydrate>
        <LazyHydrate whenVisible>
          {contentBlock(blockConfig, 'main17', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
          {contentBlock(blockConfig, 'main18', this.props, { geolocation, landingLinks, wrapper: BlockWrapper, withContainer: false })}
        </LazyHydrate>
        <LazyHydrate whenVisible>
          <Container {...bemClasses('blocks', 'row')} body>
            {contentBlock(blockConfig, 'main19', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
            {contentBlock(blockConfig, 'main20', this.props, { geolocation, landingLinks, wrapper: BlockWrapper })}
          </Container>
        </LazyHydrate>
      </>
    );
  }
}

export default Home;
