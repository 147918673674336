// import searchTranslates from '../section-projects/search-i18n';

export default function () {
  return {
    // ...searchTranslates(),

    main: {
      ru: 'Главная',
      ua: 'Головна'
    },
    portalRealEstate: {
      ru: 'Портал недвижимости',
      ua: 'Портал нерухомості'
    },

    buildingsNew: {
      ru: 'Новостройки',
      ua: 'Новобудови'
    },
    show: {
      ru: 'Показать',
      ua: 'Показати'
    },
    type: {
      ru: 'Тип',
      ua: 'Тип'
    },

    lolo: {
      ru: '',
      ua: ''
    }
  };
}
