import loadable from '@loadable/component';

const stocksViewSlider = loadable(() =>
  import(/* webpackChunkName: "block-newBuildList-stockViewSlider" */ './stocks/stocksViewSlider'));

const stocksViewSliderWithConversions = loadable(() =>
  import(/* webpackChunkName: "block-newBuildList-stockViewSlider-WithConversions" */ './stocks/stocksViewSliderWithConversions'));

const stocksInNews = loadable(() =>
  import(/* webpackChunkName: "block-newBuildList-stocksInNews" */ './stocks/stocksInNews'));

const LastTopics = loadable(() =>
  import(/* webpackChunkName: "block-lastTopics" */ './last-topics/last-topics'));

const ListArticle = loadable(() =>
  import(/* webpackChunkName: "block-listArticle" */ './news/article-list'));

const Developers = loadable(() =>
  import(/* webpackChunkName: "block-developers" */ './developers/developers'));

const UserAdvertList = loadable(() =>
  import(/* webpackChunkName: "block-userAdvert-topicView" */ 'containers/content-block/blocks/userAdvert/advertList'));

const cfg = {
  nbStocksList: {
    provider: 'domik/location/newbuilds-stocks-list',
    controller: 'admin/content-blocks/bild/newbuilds-stocks-list',
    orders: [
      { id: 0, value: 'Рандом', param: [['rand', 'asc']] }
    ],
    views: {
      stocksView: {
        name: 'Вид "Акции"',
        component: stocksViewSlider
      },
      stocksViewWC: {
        name: 'Вид "Акции" с конверсией',
        component: stocksViewSliderWithConversions
      },
      stocksInNews: {
        name: 'Вид "Акции в новостях" с конверсией',
        component: stocksInNews,
        props: { view: 'stocksInNews' }
      }
    }
  },
  lastTopics: {
    provider: 'forum/topic-list',
    controller: 'admin/content-blocks/forum/last-topics',
    orders: [
      { id: 0, value: 'свежие вверху', param: [['topic_last_post_time', 'desc']] }
    ],
    views: {
      default: {
        name: 'По умолчанию',
        component: LastTopics,
        props: { view: 'bg' }
        // props: { type: 'rand', view: 'dark', Block: BView1 }
      }
    }
  },
  news: {
    provider: 'domik/news/publication-list',
    controller: 'admin/content-blocks/domik/news',
    orders: [
      { id: 0, value: 'по времени публикации новости', param: ['standart', 'desc'] },
      { id: 1, value: 'сортировка для главной', param: [['homePage', 'desc'], ['standart', 'desc']] },
      { id: 2, value: 'сортировка для пагинации', param: [['pageList', 'desc'], ['standart', 'desc']] }
    ],
    views: {
      default: {
        name: 'По умолчанию',
        component: ListArticle
      }
    }
  },
  developers: {
    provider: 'domik/users/developers-list',
    controller: 'admin/content-blocks/domik/developers',
    orders: [
      { id: 0, value: 'По готовым домам', param: ['ready_house', 'desc'] },
      { id: 1, value: 'По строящимся домам', param: ['in_progress_house', 'desc'] }
    ],
    views: {
      default: {
        name: 'По умолчанию',
        component: Developers
      }
    }
  },
  userAdvertList: {
    provider: 'user-advert/content-advert-list',
    controller: 'admin/content-blocks/user-advert/user-advert-list',
    orders: [],
    views: {
      topicView: {
        name: 'Вид "В темах"',
        component: UserAdvertList,
        props: { view: 'topic' }
      },
      postsView: {
        name: 'Вид "В постах"',
        component: UserAdvertList,
        props: { view: 'post' }
      }
    }
  },
};

export default cfg;
