import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import Link from 'components/link';
import Row, { Col } from 'components/row';
import Container from 'components/container';

import 'styles/domik/blocks/_block-projects.scss';

const classesBlockPaginator = new Bem('blockProjects');

export default class BlockWrapper extends Component {
  static propTypes = {
    className: PropTypes.string,
    params: PropTypes.shape({}),
    content: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.shape({}),
    dataGtmItemPlace: PropTypes.string,
    external: PropTypes.bool
  };

  render() {
    const { className, params, children, dataGtmItemPlace, external } = this.props;
    const { header, footer, footer_url } = params;
    const view = get(children, 'props.view');

    if (!children) return null;

    return (
      <Container
        {...classesBlockPaginator({ extra: className, modifiers: view })}
        dataProps={dataGtmItemPlace ? { 'data-gtm-item': dataGtmItemPlace } : null}
        body
      >
        {header &&
          <Row {...classesBlockPaginator('rowTopic')}>
            <Col allPr={0}><div {...classesBlockPaginator('text', 'h2')}><h2 children={header} /></div></Col>
          </Row>
        }
        <div {...classesBlockPaginator('projects')} children={children} />
        {footer && footer_url &&
          <div {...classesBlockPaginator('linkContainer')}>
            <Link
              {...classesBlockPaginator('link')}
              href={footer_url}
              title={footer}
              external={external}
              blank={external}
            >
              <span {...classesBlockPaginator('linkContent')}>
                <span {...classesBlockPaginator('text', 'link')} children={footer} />
              </span>
            </Link>
          </div>
        }
      </Container>
    );
  }
}
