/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import DataProvider from 'api/data-provider';
import Dict, { dictConnect } from 'api/data-dict';
import map from 'lodash/map';
import { InputLocality, ComboboxInput } from 'containers/form';
import TabJSControl from 'components/tab-js-control';
import Link from 'components/link';
import cacheTerms from 'helpers/cacheTerms';

import translates from './page-home-i18n';

const bemClasses = new Bem('pageHome');

@dictConnect({
  url: 'domik/search-form/object-types',
  injectPropName: 'objTypeDict'
})
@dictConnect({
  url: 'location/living-complex/newbuild-object-search-groups',
  injectPropName: 'nbTypeDict'
})
@I18nHoc(translates())
export default class BlockSearch extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);

    this.state = {
      locality: null,
      countColumnType: 'objects',
      tabIdx: 0,
      type1: 'sFlats',
      type2: 'saleFlat'
    };

    this.tabIdx = [
      { countColumnType: 'objects', type1: 'sFlats', type2: 'saleFlat' },
      { countColumnType: 'objects', type1: 'rFlats', type2: 'rentFlat' },
      { countColumnType: 'objects', type1: null, type2: 'rentFlatDaily' },
      { countColumnType: 'newbuilds', type2: 'flats' }
    ];
  }

  handleChangeLocality = (locality) => {
    this.setState({ locality });
  }

  handleType1Change = (type1) => {
    const tIdx = this.props.objTypeDict[this.state.tabIdx];
    const t1List = tIdx.children[type1];
    const type2 = t1List.children ? Object.keys(t1List.children)[0] : null;

    this.setState({ type1, type2 });
  }

  handleType2Change = (type2) => {
    this.setState({ type2 });
  }

  renderLocalityChanger = () => {
    const { locality, countColumnType, type2 } = this.state;

    return (
      <InputLocality
        project="domik"
        countColumnType={countColumnType}
        estateType={type2}
        value={locality}
        onChange={this.handleChangeLocality}
        designMode="modal"
        confirmLocality
        noError
      />
    );
  }

  renderShowButton = ( { data } ) => {
    const { i18n } = this;
    const lData = data[0] || {};
    const url = get(lData, 'objects_landing');
    const count = get(lData, 'objects_count');

    const span = (
      <span {...bemClasses('linkContent')}>
        <span {...bemClasses('text')} children={`${i18n('show')} ~${count || 0}`} />
      </span>
    );

    if (!url) {
      return (
        <div
          {...bemClasses('link', 'toSearch disabled')}
          title={i18n('show')}
          children={span}
        />
      );
    }

    return (
      <Link
        {...bemClasses('link', 'toSearch')}
        href={url}
        title={i18n('show')}
        children={span}
      />
    );
  }

  renderShowProvider = () => {
    const { type2, locality, countColumnType } = this.state;

    return (
      <DataProvider
        url="domik/location/administrative"
        query={{
          id: locality || 1,
          columnObjectCount: {
            project: 'domik',
            type: countColumnType,
            class: type2
          }
        }}
        queryJson
        cache={cacheTerms.loc_administrative}
        lruCache
        renderer={this.renderShowButton}
      />
    );
  }

  renderObjTab = (val, key) => {
    const { i18n } = this;
    const { type1, type2 } = this.state;
    const t2items = (type1 && val.children[type1]) ? val.children[type1].children : val.children;

    return (
      <tab key={key}>
        <title>{val.name}</title>
        <div {...bemClasses('search')}>
          {this.renderLocalityChanger()}
          <div {...bemClasses('searchFilters')}>
            {type1 &&
              <ComboboxInput
                value={type1}
                onChange={this.handleType1Change}
                listBoxItems={val.children}
                dictValue="name"
                schema={{ label: i18n('type') }}
                dictDynamic
                nullValueItem={false}
                // nullValueLabel={this.props.type2nullValue}
                modalContentNoPadding={false}
                inForm
                noLabel
                wAuto
                noError
              />
            }
            {type2 &&
              <ComboboxInput
                value={type2}
                onChange={this.handleType2Change}
                listBoxItems={t2items}
                dictValue="name"
                schema={{ label: i18n('type') }}
                dictDynamic
                nullValueItem={false}
                // nullValueLabel={this.props.type2nullValue}
                modalContentNoPadding={false}
                inForm
                noLabel
                wAuto
                noError
              />
            }
          </div>
          {type2 && this.renderShowProvider()}
        </div>
      </tab>
    );
  }

  renderNbTab = () => {
    const { type2 } = this.state;
    const { nbTypeDict } = this.props;

    return (
      <tab>
        <title>{this.i18n('buildingsNew')}</title>
        <div {...bemClasses('search')}>
          {this.renderLocalityChanger()}
          <div {...bemClasses('searchFilters')}>
            {type2 &&
              <ComboboxInput
                value={type2}
                onChange={this.handleType2Change}
                listBoxItems={nbTypeDict}
                dictValue="value"
                schema={{ label: this.i18n('type') }}
                dictDynamic
                nullValueItem={false}
                modalContentNoPadding={false}
                inForm
                noLabel
                wAuto
                noError
              />
            }
          </div>
          {type2 && this.renderShowProvider()}
        </div>
      </tab>
    );
  }

  handleTabChange = (tab, idx) => {
    this.setState({ ...this.tabIdx[idx], tabIdx: idx });
  }

  render() {
    const { objTypeDict } = this.props;

    return (
      <TabJSControl
        {...bemClasses('tab')}
        onChange={this.handleTabChange}
      >
        {map(objTypeDict, this.renderObjTab)}
        {this.renderNbTab()}
      </TabJSControl>
    );
  }
}
